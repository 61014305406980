import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=f0a90e7e&scoped=true"
import script from "./Hero.vue?vue&type=script&lang=js"
export * from "./Hero.vue?vue&type=script&lang=js"
import style0 from "./Hero.vue?vue&type=style&index=0&id=f0a90e7e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0a90e7e",
  null
  
)

/* custom blocks */
import block0 from "./hero.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FHero%2FHero.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports