import { render, staticRenderFns } from "./Design.vue?vue&type=template&id=1b106b2a&scoped=true"
import script from "./Design.vue?vue&type=script&lang=js"
export * from "./Design.vue?vue&type=script&lang=js"
import style0 from "./Design.vue?vue&type=style&index=0&id=1b106b2a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b106b2a",
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FDesign%2FDesign.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports