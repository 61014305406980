import { render, staticRenderFns } from "./NavigationHeader.vue?vue&type=template&id=7a4e7b51&scoped=true"
import script from "./NavigationHeader.vue?vue&type=script&lang=js"
export * from "./NavigationHeader.vue?vue&type=script&lang=js"
import style0 from "./NavigationHeader.vue?vue&type=style&index=0&id=7a4e7b51&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a4e7b51",
  null
  
)

/* custom blocks */
import block0 from "./navigation-header.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FNavigations%2FHeader%2FNavigationHeader.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports