import { render, staticRenderFns } from "./FooterCta.vue?vue&type=template&id=50e400dc&scoped=true"
import script from "./FooterCta.vue?vue&type=script&lang=js"
export * from "./FooterCta.vue?vue&type=script&lang=js"
import style0 from "./FooterCta.vue?vue&type=style&index=0&id=50e400dc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e400dc",
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FFooterCTA%2FFooterCta.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports